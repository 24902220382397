import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../packs/lib/i18n.js'
import fetchCalendarData from './Calendar/fetchCalendarData.js'
import fetchMeetingUserStatus from './Calendar/fetchMeetingUserStatus.js'

/*eslint-disable no-unused-vars*/
import CalendarLine from './Calendar/CalendarLine.jsx'
import { el } from 'date-fns/locale'
/*eslint-enable no-unused-vars*/

class Calendar extends React.Component {
  constructor() {
    super()

    this.data = this.data.bind(this)
    this.refreshCalendar = this.refreshCalendar.bind(this)
    this.paramsForData = this.paramsForData.bind(this)
    this.onWindowResize = this.onWindowResize.bind(this)
    this.onTimeSelect = this.onTimeSelect.bind(this)
    this.onRemoveRequest = this.onRemoveRequest.bind(this)

    this.state = {
      index: 0,
      previousMaxDays: 4,
      maxDays: 4,
      data: null,
      status: 'loading',
    }
  }

  onWindowResize() {
    if (typeof window === 'undefined') {
      return
    }

    if (window.innerWidth > 540) {
      if (this.state.maxDays !== this.state.previousMaxDays) {
        this.setState({ maxDays: this.state.previousMaxDays })
      }

      return
    }

    if (this.state.maxDays === 1) {
      return
    }

    this.setState({
      maxDays: 1,
      previousMaxDays: this.state.maxDays
    })
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onWindowResize)
    }
  }

  componentDidMount() {
    this.data()

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onWindowResize)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.host === this.props.host && prevProps.dateRangeType === this.props.dateRangeType) {
      return
    }

    this.refreshCalendar()
  }

  paramsForData() {
    let params = { host: this.props.host }

    if (typeof this.props.eventId !== 'undefined') {
      params.event_id = this.props.eventId
    }

    if (typeof this.props.timeSliceId !== 'undefined') {
      params.time_slice_id = this.props.timeSliceId
    }

    if (typeof this.props.guests !== 'undefined') {
      params.guests = this.props.guests
    }

    if (typeof this.props.spotType !== 'undefined') {
      params.spot_type = this.props.spotType
    }

    if (typeof this.props.dateRangeType !== 'undefined') {
      params.date_range_type = this.props.dateRangeType
    }

    return params
  }

  async refreshCalendar() {
    await this.setState({
      status: 'loading',
      data: null,
    })
    let data = await fetchCalendarData(this.paramsForData())

    for (let busySlice in data.data.busy_slices) {
      let currentBusySlice = data.data.busy_slices[busySlice]

      if (typeof currentBusySlice.status !== 'undefined' && currentBusySlice.status !== 'canceled') {
        data.data.busy_slices[busySlice].user_status =
          await fetchMeetingUserStatus(this.paramsForData(), currentBusySlice.id)
      }
    }

    this.setState(data)
  }

  async data() {
    if (this.state.data !== null) {
      return
    }

    await this.refreshCalendar()
  }

  formatDate(date) {
    if (date) {
      return date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2')
    }

    return ""
  }

  weekDate(date) {
    if (date) {
      const dateFormat = new Date(date + "T12:00:00.000")
      return t(`weekday${dateFormat.getDay()}`)
    }

    return ""
  }

  onRemoveRequest(id) {
    let data = this.state.data
    const busySlices = data.busy_slices

    let keys = Object.keys(busySlices)
    let busy_slices = {}
    Object.values(busySlices).forEach((item, index) => {
      if (item.id === id) {
        return
      }

      busy_slices[keys[index]] = item
    })

    data = {...data, busy_slices}

    this.setState({ data })
  }

  onTimeSelect(event, timeStr) {
    event.preventDefault()
    this.props.onTimeSelect(timeStr, (newData) => {
      let data = this.state.data
      data.busy_slices[newData.starts_at] = newData
      this.setState({ data })
    })
  }

  render() {
    // TODO: Make loading layout
    if (this.state.status === 'loading') {
      return <p>Loading...</p>
    }

    // TODO: Make error layout
    if (this.state.status === 'error') {
      return <p>Error!</p>
    }

    const { index, maxDays, data } = this.state
    const { config } = data

    const maxIndex = Math.ceil(config.days.length * 1.0 / maxDays) - 1
    const pagingRest = config.days.length % maxDays
    const sliceStart = index * maxDays

    const sliceEnd = (pagingRest > 0 && maxIndex == index)
      ? sliceStart + pagingRest
      : sliceStart + maxDays

    const daysSlice = config.days.slice(sliceStart, sliceEnd)

    const rowTitles = [].concat([''], daysSlice)

    const lineTitles = config.steps.filter((item, index, array) => {
      const next = array[index + 1]
      return next && next !== 'timeskip'
    })

    const calendarKlass = `c-calendar__grid c-calendar__grid--row-count-${maxDays}`

    return <React.Fragment>
      <div className="c-calendar">
        <nav className="c-calendar__nav">
          { index > 0
            ? <button className="c-calendar__nav__left" type="nav" onClick={ (event) => {
              event.preventDefault()

              this.setState({
                index: index - 1
              })
            }}><span>&lsaquo;</span> <small>{ t('previous') }</small></button>
            : '' }

          { index < maxIndex
            ? <button className="c-calendar__nav__right" type="nav" onClick={ (event) => {
              event.preventDefault()
              this.setState({
                index: index + 1
              })
            }}><small>{ t('next') }</small> <span>&rsaquo;</span></button>
            : '' }
        </nav>

        {
          this.props.availableAttendanceTypes &&
          this.props.eventTimeZone &&
          <div className="c-calendar__cell--row-title c-calendar__attendanceTypes u-mt2">
            {t(`time_zone_warning_for_${this.props.dateRangeType}`, { event_time_zone: this.props.eventTimeZone })}
          </div>
        }

        <div className={calendarKlass}>
          {
            rowTitles.map((title, rowIndex) => {
              return (
                <React.Fragment key={`calendar-row-${rowIndex}`} >
                  <div className="c-calendar__row">
                    <div className="c-calendar__cell c-calendar__cell--row-title">
                      {this.formatDate(title)}
                      <br />
                      <span className="week-day">{this.weekDate(title)}</span>
                    </div>

                    {
                      lineTitles.map((lineTitle, lineIndex) => {
                        const key = `calendar-line-${rowIndex}-${lineIndex}`

                        return <CalendarLine
                          host={this.props.host}
                          onRemoveRequest={this.onRemoveRequest}
                          key={key}
                          title={title}
                          parentState={this.state}
                          cellInstruction={this.props.cellInstruction}
                          onTimeSelect={this.onTimeSelect}
                          rowIndex={rowIndex}
                          lineTitle={lineTitle}
                          disableModals={this.props.disableModals}
                          cellDisabledByUser={this.props.cellDisabledByUser}
                        />
                      })
                    }
                  </div>

                  { index > 0 && rowIndex === 0 &&
                    <div className="c-calendar__row c-calendar__row--hellip">
                      <div className="c-calendar__cell c-calendar__cell--row-title">
                        &hellip;
                      </div>
                      {
                        lineTitles.map((lineTitle, lineIndex) => {
                          let className = 'c-calendar__cell'
                          if (lineTitle === 'timeskip') {
                            className = 'c-calendar__cell c-calendar__cell--timeskip'
                          }

                          return (
                              <div key={`cell-start-hellip-${lineIndex}`} className={className}>
                                &hellip;
                              </div>
                            )
                          }
                        )
                      }
                    </div>
                  }
                </React.Fragment>
              )
            })
          }
          { index < maxIndex &&
            <div className="c-calendar__row c-calendar__row--hellip">
              <div className="c-calendar__cell c-calendar__cell--row-title">
                &hellip;
              </div>
              {
                lineTitles.map((lineTitle, lineIndex) => {
                  let className = 'c-calendar__cell'
                  if (lineTitle === 'timeskip') {
                    className = 'c-calendar__cell c-calendar__cell--timeskip'
                  }

                  return (
                    <div key={`cell-start-hellip-${lineIndex}`} className={className}>
                      &hellip;
                    </div>
                  )
                  }
                )
              }
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  }
}

Calendar.propTypes = {
  onTimeSelect: PropTypes.func,
  cellInstruction: PropTypes.string,
  host: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  dateRangeType: PropTypes.string,
  guests: PropTypes.array,
  spotType: PropTypes.string,
  disableModals: PropTypes.bool
}

export default Calendar
