import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../packs/lib/i18n.js'
import axios from '../packs/lib/axios.js'

/*eslint-disable no-unused-vars*/
import MessagesNav from './Messages/MessagesNav.jsx'
import CurrentChat from './Messages/CurrentChat.jsx'
/*eslint-enable no-unused-vars*/


const EMPTY_CHAT = {
  id: null,
  active: false,
  newChat: false,
}

const NEW_CHAT = {
  id: null,
  active: true,
  newChat: true,
}

const chatPreviewTemplate = () => (
  {
    id: null,
    title: t('messages.index.new_thread'),
    multipleUsers: false,
    avatar: 'https://placehold.it/100x100',
    lastMessage: {
      sender: '',
      message: '',
      sentAt: 'now',
      avatar: 'https://placehold.it/100x100'
    }
  }
)

class Messages extends React.Component {
  constructor(props) {
    super(props)

    this.loadChat = this.loadChat.bind(this)
    this.removeNewChatPreview = this.removeNewChatPreview.bind(this)
    this.newMessageInstance = this.newMessageInstance.bind(this)
    this.showMessage = this.showMessage.bind(this)
    this.afterCreation = this.afterCreation.bind(this)
    this.backToList = this.backToList.bind(this)

    this.state = {
      chats: [],
      currentChat: props.currentChat || EMPTY_CHAT,
      loading: true,
    }
  }

  async loadChat() {
    try {
      const res = await axios.get('/messages.json')
      this.setState({ chats: res.data, loading: false })
    } catch (err) {
      this.setState({ loading: false })
    }
  }

  removeNewChatPreview() {
    const chats = this.state.chats.filter((chat) => chat.id !== null)
    this.setState({ chats })
  }

  backToList() {
    this.removeNewChatPreview()
    this.setState({ currentChat: EMPTY_CHAT })
  }

  newMessageInstance(event) {
    event.preventDefault()

    const oldChats = this.state.chats.filter((x) => x.id !== null)
    const chats = [].concat([chatPreviewTemplate()], oldChats)

    this.setState({ chats, currentChat: NEW_CHAT })
  }

  showMessage(id) {
    this.removeNewChatPreview()
    this.setState({
      currentChat: { id, active: true, newChat: false }
    })
  }

  afterCreation(id) {
    this.showMessage(id)
    this.loadChat()
  }

  componentDidMount() {
    this.loadChat()
  }

  render() {
    return <section id="messages" className="c-messages">
      <div className="c-alert c-alert--info">
        <p>
          { t('messages.no_auto_refresh') }
          <button onClick={ (e) => {
            e.preventDefault()

            document &&
            document.location.reload()
          }}>{ t('messages.click_to_reload') }</button>
        </p>
      </div>

      <MessagesNav
        loading={this.state.loading}
        currentChatId={this.state.currentChat.id}
        chats={this.state.chats}
        newMessageInstance={this.newMessageInstance}
        onSelect={this.showMessage}
      />
      {!this.state.loading && (
        <CurrentChat
          afterCreation={this.afterCreation}
          afterMessageSubmit={this.loadChat}
          backToList={this.backToList}
          chat={this.state.currentChat}
        />
      )}
    </section>
  }
}

Messages.propTypes = {
  currentChat: PropTypes.shape({
    id: PropTypes.number,
    active: PropTypes.bool,
    newChat: PropTypes.bool,
  }),
}

export default Messages
