import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../packs/lib/i18n.js'
import fetchCalendarData from './Calendar/fetchCalendarData.js'

import "react-datepicker/dist/react-datepicker.css"

/*eslint-disable no-unused-vars*/
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { enUS, es, ptBR } from 'date-fns/locale'
import { addMinutes } from 'date-fns'
import CalendarDropdownMeeting from './CalendarDropdownMeeting.jsx'
/*eslint-enable no-unused-vars*/

class CalendarDropdown extends React.Component {
  constructor() {
    super()

    this.data = this.data.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.paramsForData = this.paramsForData.bind(this)
    this.onTimeSelect = this.onTimeSelect.bind(this)
    this.onDaySelect = this.onDaySelect.bind(this)
    this.onRemoveRequest = this.onRemoveRequest.bind(this)

    registerLocale('es', es)
    registerLocale('en', enUS)
    registerLocale('pt-BR', ptBR)

    this.state = {
      index: 0,
      previousMaxDays: 4,
      maxDays: 4,
      data: null,
      status: 'loading',
      selected_day: null
    }
  }

  componentDidMount() {
    this.data()
  }

  onRemoveRequest(id) {
    let data = this.state.data
    const busySlices = data.busy_slices

    let keys = Object.keys(busySlices)
    let busy_slices = {}
    Object.values(busySlices).forEach((item, index) => {
      if (item.id === id) {
        return
      }

      busy_slices[keys[index]] = item
    })

    data = {...data, busy_slices}

    this.setState({ data })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.host === this.props.host && prevProps.dateRangeType === this.props.dateRangeType) {
      return
    }

    this.refreshData()
  }

  paramsForData() {
    let params = { host: this.props.host }

    if (typeof this.props.eventId !== 'undefined') {
      params.event_id = this.props.eventId
    }

    if (typeof this.props.guests !== 'undefined') {
      params.guests = this.props.guests
    }

    if (typeof this.props.spotType !== 'undefined') {
      params.spot_type = this.props.spotType
    }

    if (typeof this.props.dateRangeType !== 'undefined') {
      params.date_range_type = this.props.dateRangeType
    }

    return params
  }

  async refreshData() {
    await this.setState({
      status: 'loading',
      data: null,
    })
    const data = await fetchCalendarData(this.paramsForData())
    this.setState(data)
  }

  async data() {
    if (this.state.data !== null) {
      return
    }

    await this.refreshData()
  }

  onTimeSelect(something) {
    if (!this.state.selected_day) {
      return
    }

    const timeStr = `${this.state.selected_day} ${something.value}:00` // This should be enough
    this.props.onTimeSelect(timeStr, (newData) => {
      let data = this.state.data
      data.busy_slices[newData.starts_at] = newData
      this.setState({ data })
    })
  }

  onDaySelect(selectedDate) {
    this.setState({
      selected_day: selectedDate.toISOString().substring(0, 10)
    })
  }

  render() {
    if (this.state.status === 'loading') {
      return <p>Loading...</p>
    }

    if (this.state.status === 'error') {
      return <p>Error!</p>
    }

    const { index, maxDays, data } = this.state
    const { config } = data

    const daysOptions = config.days.map((config_day) => {
      const baseDate = new Date(config_day)

      return addMinutes(baseDate, baseDate.getTimezoneOffset())
    })

    const onlineMeetings = Object.entries(data.busy_slices).filter((x) => x[1].type == 'online_meeting')
    const noonlineMeetings = onlineMeetings.length === 0

    const busySlices = Object.keys(data.busy_slices)

    let timeOptions
    if (!this.state.selected_day) {
      timeOptions = []
    } else {
      timeOptions = config.steps.map((stepItem) => {
        const bsKey = `${this.state.selected_day} ${stepItem}:00`
        const disabled = busySlices.includes(bsKey)
        const label = (disabled && data.busy_slices[bsKey].type === 'block') ?
          `${stepItem} - ${t('blocked_by_user')}` : stepItem

        return {
          value: stepItem,
          label: label,
          isDisabled: disabled
        }
      })
    }

    if (this.props.disableIfNoMeetings && noonlineMeetings) {
      return <div className='calendarDropdown' key='calendarDropdown'>
        <a href='/companies' class='no-online-meetings'>{t('no_online_meetings')}</a>
      </div>
    }

    return <div className='calendarDropdown' key='calendarDropdown'>
      {
        this.props.availableAttendanceTypes &&
        this.props.eventTimeZone &&
        <div className="c-calendar__cell--row-title c-calendar__attendanceTypesOnlineMeeting">
          {t(`time_zone_warning_for_${this.props.dateRangeType}`, { user_time_zone: this.props.userTimeZone })}
        </div>
      }
      {this.props.listOnlineMeetings &&
      <div className='calendarDropdownScheduledMeetings'>
        {onlineMeetings && onlineMeetings.map((oMeeting) => {
          return <CalendarDropdownMeeting
            key={oMeeting[0]}
            host={this.props.host}
            busy={oMeeting[1]}
            onRemoveRequest={this.onRemoveRequest}
          />
        })}
      </div>
      }
      <div className='calendarDropdownSelectWrapper'>
        <div className='calendarDropdownSelect'>
          <DatePicker
            selected={this.state.selected_day ? new Date(`${this.state.selected_day}`) : null}
            includeDates={daysOptions}
            onChange={this.onDaySelect}
            placeholderText={this.props.customDatepickerPlaceholder ? this.props.customDatepickerPlaceholder : t('select_day_placeholder')}
            popperPlacement="top"
            locale={window.locale}
            dateFormat='P'
          />
        </div>
        <div className='calendarDropdownSelect'>
          <Select
            defaultValue={this.state.selected_day}
            options={timeOptions}
            onChange={this.onTimeSelect}
            placeholder={t('select_time_placeholder')}
          />
        </div>
      </div>
    </div>
  }
}

CalendarDropdown.propTypes = {
  onTimeSelect: PropTypes.func,
  host: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  dateRangeType: PropTypes.string,
  guests: PropTypes.array,
  spotType: PropTypes.string,
  disableIfNoMeetings: PropTypes.bool,
  listOnlineMeetings: PropTypes.bool,
  customDatepickerPlaceholder: PropTypes.string
}

export default CalendarDropdown
