/* global PropTypes, i18n */

import * as datetime from '../../../lib/datetime'
/*eslint-disable no-unused-vars*/
import EditsAlert from './EditsAlert.jsx'
/*eslint-enable no-unused-vars*/

const t = i18n.t

const OnlineMeetingSpot = ({ spotName }) => (
  <li>
    <strong>{t('online_spot')}:</strong>
    {spotName.split(' ').map((particle) => (
      particle.includes('http')
        ? <span> <a href={particle} target="_blank">{particle}</a> </span>
        : `${particle} `
    ))}
  </li>
)

const relativeDatetime = (datetimeString, fromOffset, toOffset) => {
  const toDate = datetime.convertTimezones(datetimeString, fromOffset, toOffset)
  return datetime.dateToShortDatetime(toDate)
}

const MeetingSummary = ({
  currentView,
  state,
  onClickEditDatetime,
  onClickEditOnlineMeetingUrl,
  onClickDestroyAction,
  onSave,
  onClose,
  onClickConfirmAction,
  changeState,
  parentProps,
}) => {
  const { edits } = state
  const { length, starts_at, spot, online_spot, users, status, time_zone_utc_offset, chat_id } = state.data
  let spotName = (spot) ? spot : t('auto_spot')
  const alertKlass = `c-inline-alert c-inline-alert--${status}`
  const currentTimezone = time_zone_utc_offset
  const online = state.data.meeting_type === 'online'

  if (currentView !== 'MeetingSummary') {
    return ''
  }


  return <React.Fragment>
    <div className="c-modal__content c-meeting">
      <EditsAlert edits={state.edits} />

      <h2>{t('meeting_summary')}</h2>
      <ul className="u-plain-list">
        <li><strong>{t('status')}:</strong> <span className={alertKlass}>{t(status)}</span></li>

        {
          edits.datetime
            ? (<React.Fragment>
              <li><hr/></li>
              <li><strong>{t('old_datetime')}:</strong> {datetime.toShortDatetime(starts_at)}</li>
              <li><strong>{t('new_datetime')}:</strong> {datetime.toShortDatetime(edits.datetime)}</li>
              <li><hr/></li>
            </React.Fragment>)
            : <li>
              <strong>{t('datetime')}:</strong> {datetime.toShortDatetime(starts_at)}
              {online && ` (${t('your_timezone')})`}
            </li>
        }

        <li><strong>{t('length')}:</strong> {length} {t('minutes')}</li>
        <li><strong>{t('meeting_type')}:</strong> {t(`meeting_type_${state.data.meeting_type}`)}</li>

        {
          edits.onlineMeetingUrl
            ? (<React.Fragment>
              <li><hr/></li>
              <li><strong>{t('old_online_spot')}:</strong> {spotName}</li>
              <li><strong>{t('new_online_spot')}:</strong> {edits.onlineMeetingUrl}</li>
              <li><hr/></li>
            </React.Fragment>)
            : online_spot
              ? spotName.includes('http')
                ? <OnlineMeetingSpot spotName={spotName} />
                : <li><strong>{t('online_spot')}:</strong> {spotName}</li>
              : <li><strong>{t('spot')}:</strong> {spotName}</li>
        }
        { online_spot && online
          ? <li>
            <strong>
              <a href="#" onClick={(e) => {
                e.preventDefault()
                onClickEditOnlineMeetingUrl()
              }}>{t('edit_online_meeting_url')}</a>
            </strong>
          </li>
          : ''
        }
        <li>
          <strong>
            <a href="#" onClick={(e) => {
              e.preventDefault()
              onClickEditDatetime()
            }}>{t('edit_datetime')}</a>
          </strong>
        </li>
        { chat_id
          ? <li>
            <strong>
              <a href={'/messages/' + chat_id} >{t('view_message')}</a>
            </strong>
          </li>
          : ''
        }

        {
          (state.data.destroy_action || state.data.can_confirm)
            ? <li className="u-display-flex u-mt1">
              {
                state.data.can_confirm &&
                  <a href="#" className="c-btn u-mr1" onClick={(e) => {
                    e.preventDefault()
                    onClickConfirmAction()
                  }}>
                    {t('click_here_to', { action: t('confirm').toLowerCase() })}
                  </a>
              }
              {
                state.data.destroy_action &&
                  <a href="#" className="c-btn c-btn--danger" onClick={(e) => {
                    e.preventDefault()
                    onClickDestroyAction(state.data.destroy_action)
                  }}>
                    {t('click_here_to', { action: t(state.data.destroy_action).toLowerCase() })}
                  </a>
              }
            </li>
            : ''
        }
        { state.data.user_status !== undefined &&
          state.data.user_status !== 'canceled' &&
        <li className="u-display-flex u-mt1">
          <a href="#" className="c-btn" onClick={(e) => {
            e.preventDefault()
            changeState('editGuests')
          }}>
            { t('edit_guests.edit_guests_button') }
          </a>
          {
            (parentProps.userId === state.data.host_id) &&
          <a href="#" className="c-btn u-ml1" onClick={(e) => {
            e.preventDefault()
            changeState('editHosts')
          }}>
            { t('edit_guests.edit_hosts_button') }
          </a>
          }
        </li>
        }
      </ul>

      <h2>{t('participants_statuses')}</h2>

      <ul className="u-plain-list c-meeting__users">
        { users.map((user) => {
          const ustatus = (status == 'canceled' && user.host) ? 'canceled' : user.status
          const alertKlass = `c-inline-alert c-inline-alert--${ustatus}`
          return (
            <li className="c-meeting__user"
              key={`show-modal-${state.data.id}-${user.id}`}>
              <div className="c-meeting__user__img">
                <img src={user.avatar} />
              </div>

              <div className="c-meeting__user__info">
                <h3>{user.name}
                  {user.company_id &&
                 <span> (<a href={'/companies/' + user.company_id}>{user.company_name}</a>)</span>
                  }
                </h3>
                <p><strong>{t('status')}:</strong> <span className={alertKlass}>{t(ustatus)}</span></p>
                { user.host
                  ? <p><small>*{t('is_host')}</small></p>
                  : '' }
                {
                  online_spot
                    ? edits.datetime
                      ? (
                        <p>
                          <hr/>
                          <small><strong>{t('old_datetime_for_person')}:</strong> {relativeDatetime(starts_at, currentTimezone, user.time_zone_utc_offset)}</small><br/>
                          <small><strong>{t('new_datetime_for_person')}:</strong> {relativeDatetime(edits.datetime, currentTimezone, user.time_zone_utc_offset)}</small><br/>
                          <hr/>
                        </p>
                      )
                      : (
                        <p>
                          <small><strong>{t('datetime_for_person')}:</strong> {relativeDatetime(starts_at, currentTimezone, user.time_zone_utc_offset)}</small>
                        </p>
                      )
                    : ''
                }
              </div>
            </li>
          )
        })}
      </ul>
    </div>


    <footer className="c-modal__footer">
      {
        Object.keys(edits).length > 0
          ? (
            <div className="c-modal__footer__buttons">
              <button
                className="c-btn c-btn--secondary u-float-left"
                onClick={onClose}>
                {t('cancel')}
              </button>

              <button
                className="c-btn c-btn--secondary u-float-right"
                onClick={onSave}>
                {t('save')}
              </button>
            </div>
          )
          : ''
      }
    </footer>
  </React.Fragment>
}

MeetingSummary.propTypes = {
  state: PropTypes.object.isRequired
}

export default MeetingSummary
