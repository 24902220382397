import * as dom from '../../../lib/dom.js'
import * as i18n from '../../../lib/i18n.js'
import currentTicketInput from './currentTicketInput.js'

const t = i18n.t

function formatNumber(num) {
  if (window.locale === 'en') {
    return parseFloat(num).toFixed(2)
  }

  return parseFloat(num)
    .toFixed(2)
    .replace(/,/, ':::')
    .replace(/\./, ',')
    .replace(/:::/, '.')
}

function formatCurrency(num, unit) {
  if (num === 0 || !num) {
    return t('free')
  }

  let fNum = formatNumber(num).replace('-', '')

  if (parseFloat(num) < 0) {
    return `-${unit} ${fNum}`
  }

  return `${unit} ${fNum}`
}

const couponDiscount = (coupon, price) => {
  const type = coupon.discount_type
  const value = parseFloat(coupon.discount_value)

  if (type === 'percentage') {
    return price * (value / 100.0)
  }

  if (value > price) {
    return price
  }

  return value
}

const handlePriceStateFx = (finalPrice) => {
  const btn = document.querySelector('.c-form--ticket-choices button[type="submit"]')

  if (finalPrice <= 0) {
    btn.setAttribute('data-is-free', 'true')
    return
  }

  btn.setAttribute('data-is-free', 'false')
}

const data = () => {
  const ticket = dom.allData(currentTicketInput())
  const coupon = dom.allData(document.querySelector('.js-coupon-data'))
  const price = parseFloat(ticket.price)
  const upgradeDiscount = ticket.upgradeDiscount === undefined
    ? 0
    : parseFloat(ticket.upgradeDiscount)

  if (ticket['coupon-only'] === 'true') {
    return {
      ticket: ticket.name,
      price: t('coupon_only')
    }
  }

  let items = {
    ticket: ticket.name,
    price: formatCurrency(ticket.price, 'R$')
  }

  if (upgradeDiscount > 0) {
    items.upgrade_discount = formatCurrency(upgradeDiscount * -1, 'R$')
  }

  if (coupon.applied !== 'true') {
    const finalPrice = price - upgradeDiscount

    items.line = ''
    items.total = formatCurrency(price - upgradeDiscount, 'R$')

    handlePriceStateFx(finalPrice)

    return items
  }

  const cDiscount = couponDiscount(coupon, price)
  const finalPrice = price - upgradeDiscount - cDiscount

  items.coupon_discount = formatCurrency(cDiscount * -1, 'R$')
  items.line = ''
  items.total = finalPrice < 0
    ? formatCurrency(0, 'R$')
    : formatCurrency(finalPrice, 'R$')

  handlePriceStateFx(finalPrice)

  return items
}

const itemTemplate = (key, value) => {
  if (value === '') {
    return '<li class="u-borderless"><hr/></li>'
  }

  return `<li><strong>${t(key)}</strong> <span>${value}</span></li>`
}

const htmlTemplate = (items) => {
  const lis = Object
    .keys(items)
    .map((key) => itemTemplate(key, items[key]))
    .join('')

  return `
    <ul class="u-plain-list">
      ${lis}
    </ul>
  `
}

export default function() {
  const contentTarget = document.querySelector('.c-ticketeer__summary__body')
  if (!contentTarget) {
    return
  }

  const d = data()
  const h = htmlTemplate(d)

  contentTarget.innerHTML = h
}

export { currentTicketInput }
