/* global dom */

import summaryUpdate from './summaryUpdate.js'
import paymentChoicesMgmt from './paymentChoicesMgmt.js'

dom.ready(() => {
  const inputs = document.querySelectorAll('[name="ticket_order[ticket_id]"]')
  dom.forEach(inputs, (input) => {
    input.addEventListener('change', () => {
      paymentChoicesMgmt()
      summaryUpdate()
      document.getElementById("ticketeer__order").scrollIntoView()
    })
  })
})
