/* global React, PropTypes, i18n */

import * as datetimeLib from '../../lib/datetime'

const t = i18n.t

const presentableGuesttime = (fromDateString, guestOffset, hostOffset) => {
  const toDate = datetimeLib.convertTimezones(fromDateString, hostOffset, guestOffset)
  return datetimeLib.dateToShortDatetime(toDate)
}

class ThirdStep extends React.Component {
  constructor() {
    super()

    this.onPrevious = this.onPrevious.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      creationData: {
        message: ''
      },
      allPossibleGuests: []
    }
  }

  onPrevious(e) {
    e.preventDefault()
    this.props.onPrevious()
  }

  onSubmit(e) {
    e.preventDefault()

    if (this.props.submittingInvitation) {
      return false
    }

    this.props.onSubmit(this.state.creationData)
  }

  render() {
    if (!this.props.isActive) {
      return ''
    }

    const { currentData, possibleGuestsData, userId } = this.props
    const guestData = currentData.guestData
    const host = this.props.currentData.hostData
    const onlineMeeting = this.props.currentData.meetingType === 'online'
    const datetime = datetimeLib.toShortDatetime(currentData.datetime)
    const spot = t(`spot_type_${currentData.spotType}`)

    let submitButtonClass = 'c-btn c-btn--secondary u-float-right'
    if (this.props.submittingInvitation) {
      submitButtonClass += ' c-btn--loading'
    }

    return <React.Fragment>
      <div className="c-modal__content c-invite-modal__step3">
        <h2>{t('check_meeting_data')}</h2>

        <ul>
          {
            onlineMeeting
            ? <li><strong>{t('datetime')}:</strong> {datetime} <small>({t('your_time_zone', { time_zone: host.time_zone_utc_offset })})</small></li>
            : <li><strong>{t('datetime')}:</strong> {datetime}</li>
          }
          <li><strong>{t('meeting_type')}:</strong> {t(`meeting_type_${currentData.meetingType}`)}</li>
          <li><strong>{t('spot')}:</strong> {spot}</li>
          <li className="u-mb1">
            <strong>{t('guest_list')}:</strong>
            <ul>
              {
                onlineMeeting
                ? guestData.map((guest) => (
                  <li key={guest.company_name}>{guest.company_name} <small className="u-c-danger">({t('in_guest_time_zone', { time: presentableGuesttime(currentData.datetime, guest.time_zone_utc_offset, host.time_zone_utc_offset) })})</small></li>
                ))
                : guestData.map((guest) => (
                  <li key={guest.company_name}>{guest.company_name}</li>
                ))
              }
            </ul>
          </li>
        </ul>

        <h3>{t('send_message_if_you_wish')}</h3>
        <textarea
          onChange={(e) => {
            const creationData = { message: e.target.value }
            this.setState({ creationData })
          }}
        ></textarea>

        {
          onlineMeeting &&
            this.props.eventAttendanceType == 'mixed' &&
            <div className="c-alert">
              <div className="u-mt0">
                {t('online_meeting_warning')}
              </div>
            </div>
        }
      </div>
      <footer className="c-modal__footer">
        <button
          className="c-btn c-btn--secondary u-float-left"
          type="previous"
          onClick={this.onPrevious}>
          {t('back_to_datetime_picker')}
        </button>

        <button
          className={submitButtonClass}
          type="previous"
          onClick={this.onSubmit}>
          {t('confirm_meeting')}
        </button>
      </footer>
    </React.Fragment>
  }
}

ThirdStep.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentData: PropTypes.object.isRequired,
  submittingInvitation: PropTypes.bool.isRequired
}

export default ThirdStep
