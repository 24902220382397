/*eslint-disable no-unused-vars*/
import React from 'react'
import EmptyChat from './EmptyChat.jsx'
import NewChat from './NewChat.jsx'
import ShowChat from './ShowChat.jsx'
/*eslint-enable no-unused-vars*/

import PropTypes from 'prop-types'

const CurrentChat = ({ chat, afterCreation, afterMessageSubmit, backToList }) => {
  if (!chat.active) {
    return <EmptyChat />
  }

  if (chat.newChat) {
    return <NewChat
      afterCreation={afterCreation}
      backToList={backToList}
    />
  }

  return <ShowChat
    id={chat.id}
    backToList={backToList}
    afterMessageSubmit={afterMessageSubmit}
  />
}

CurrentChat.propTypes = {
  chat: PropTypes.object.isRequired,
  afterCreation: PropTypes.func.isRequired,
  afterMessageSubmit: PropTypes.func.isRequired,
  backToList: PropTypes.func.isRequired
}

export default CurrentChat
