/* global React, PropTypes, i18n, swal, axios */

const t = i18n.t

/*eslint-disable no-unused-vars*/
import Loading from './ShowModal/Loading.jsx'
import MeetingSummary from './ShowModal/MeetingSummary.jsx'
import EditDatetime from './ShowModal/EditDatetime.jsx'
import EditOnlineMeetingUrl from './ShowModal/EditOnlineMeetingUrl.jsx'
import EditGuestsBox from '../EditGuestsBox'
import EditHostsBox from '../EditHostsBox'
/*eslint-enable no-unused-vars*/

class ShowModal extends React.Component {
  constructor() {
    super()

    this.onClose = this.onClose.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onClickDestroyAction = this.onClickDestroyAction.bind(this)
    this.onClickConfirmAction = this.onClickConfirmAction.bind(this)
    this.backToSummary = this.backToSummary.bind(this)
    this.changeState = this.changeState.bind(this)

    this.state = {
      view: 'MeetingSummary',
      status: 'loading',
      edits: {},
      data: {},
    }
  }

  componentDidMount() {
    // this.data()
  }

  refreshPage() {
    window &&
      window.location &&
      window.location.href &&
      window.location.reload()
  }

  async onClickConfirmAction() {
    const timeSliceId = this.props.timeSliceId
    const url = `/time_slices/${timeSliceId}/users/${this.props.userId}`
    const response = await axios.patch(url, {
      time_slice_users: { action: 'confirm' }
    })

    this.refreshPage()
  }

  async onClickDestroyAction(action) {
    let message = ''

    const confirmation = await swal({
      title: t('are_you_sure_question'),
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_sure'),
      cancelButtonText: t('negative')
    })

    if (!confirmation.value) {
      return
    }

    const messageQuestion = await swal({
      title: t(`do_you_want_to_send_a_message_${action}`),
      text: t('the_message_is_optional'),
      input: 'textarea',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t(`${action}_meeting`),
      cancelButtonText: t('give_up_and_keep_meeting')
    })

    if (messageQuestion.dismiss) {
      return
    }

    message = messageQuestion.value

    const timeSliceId = this.props.timeSliceId
    const url = `/time_slices/${timeSliceId}/users/${this.props.userId}`
    const response = await axios.patch(url, {
      time_slice_users: { action, message }
    })

    if (!response.data.success) {
      swal({
        title: t('error'),
        type: 'warning',
        text: t(response.data.error),
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'OK'
      })

      return
    }

    this.refreshPage()
  }

  async onSave(event) {
    event.preventDefault()

    let message = ''

    const messageQuestion = await swal({
      title: t('send_message_explaining_the_changes'),
      text: t('the_message_is_optional'),
      input: 'textarea',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('save_changes'),
      cancelButtonText: t('cancel_save')
    })

    if (messageQuestion.dismiss) {
      return
    }

    message = messageQuestion.value

    const timeSliceId = this.props.timeSliceId
    const response = await axios.patch(`/time_slices/meetings/${timeSliceId}`, {
      time_slice: { ...this.state.edits, message }
    })

    const data = response.data

    if (data.success) {
      swal({
        title: t('meeting_updated'),
        type: 'success',
        text: t('now_just_wait'),
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'OK'
      }).then(() => {
        this.refreshPage()
      })

      this.onClose({
        preventDefault: () => {}
      })

      return
    }

    swal({
      title: t('error'),
      type: 'warning',
      text: t(data.error),
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK'
    })

    this.onClose({
      preventDefault: () => {}
    })
  }

  onClose(event) {
    event.preventDefault()
    this.setState({ edits: {}, view: 'MeetingSummary', status: 'loading' })
    this.props.onClose()
  }

  backToSummary() {
    this.setState({ edits: {}, view: 'MeetingSummary' })
  }

  async data() {
    if (this.state.status !== 'loading') {
      return ''
    }

    try {
      const timeSliceId = this.props.timeSliceId
      const response = await axios.get(`/time_slices/meetings/${timeSliceId}`)
      const data = response.data

      if (typeof data.error !== 'undefined') {
        swal({
          title: t('error'),
          type: 'warning',
          text: t(data.error),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'OK'
        })

        this.setState({ status: 'error' })
        return
      }

      this.setState({
        status: 'loaded',
        data
      })
    } catch (error) {
      swal({
        title: t('error'),
        type: 'warning',
        text: t('unknown_error'),
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'OK'
      })

      this.setState({ status: 'error' })
    }
  }

  changeState(newStateName) {
    this.setState({ status: newStateName })
  }

  render() {
    if (this.props.hidden) {
      return ''
    }

    if (this.state.status === 'editGuests') {
      return <EditGuestsBox
        userId={this.props.userId}
        companyId={this.props.userCompanyId}
        sliceId={this.props.timeSliceId}
        hostCompanyName={this.props.hostCompanyName}
        targetCompanyId={this.props.targetCompanyId}
        targetCompanyName={this.props.targetCompanyName}
        changeParentState={this.changeState}
        closeParent={this.onClose} />
    }

    if (this.state.status === 'editHosts') {
      return <EditHostsBox
        companyId={this.props.userCompanyId}
        sliceId={this.props.timeSliceId}
        hostCompanyName={this.props.hostCompanyName}
        targetCompanyId={this.props.targetCompanyId}
        targetCompanyName={this.props.targetCompanyName}
        changeParentState={this.changeState}
        closeParent={this.onClose} />
    }

    this.data()

    if (this.state.status === 'error') {
      return ''
    }

    const { userCompany, targetCompany } = this.props

    if (this.state.status === 'loading') {
      return <Loading
        userCompany={userCompany}
        targetCompany={targetCompany}
        onClose={this.onClose} />
    }

    return <section className="c-modal c-modal--show-ts" onClick={this.onClose}>
      <div className="c-modal__wrapper" onClick={(event) => event.stopPropagation()}>
        <header className="c-modal__header">
          <h1>
            {t('meeting_from_with', {
              host_company: userCompany.name,
              guest_company: targetCompany.name
            })}
          </h1>

          <button type="close" onClick={this.onClose}>&times;</button>
        </header>

        {
          this.state.view === 'EditOnlineMeetingUrl' &&
          <EditOnlineMeetingUrl
            backToSummary={this.backToSummary}
            onNewOnlineMeetingUrl={(onlineMeetingUrl) => {
              if (onlineMeetingUrl.trim() === '' || onlineMeetingUrl.trim() === this.state.data.spot) {
                this.setState({
                  view: 'MeetingSummary'
                })
                return
              }

              const edits = Object.assign({}, this.state.edits, {
                onlineMeetingUrl
              })

              this.setState({
                view: 'MeetingSummary',
                edits
              })
            }}
            currentView={this.state.view}
            onPrevious={() => {}}
            parentProps={this.props}
            state={this.state}
          />
        }

        <EditDatetime
          backToSummary={this.backToSummary}
          onDatetimePick={(datetime) => {
            const edits = Object.assign({}, this.state.edits, {
              datetime
            })

            this.setState({
              view: 'MeetingSummary',
              edits
            })
          }}
          currentView={this.state.view}
          onPrevious={() => {}}
          parentProps={this.props}
          state={this.state}
        />

        <MeetingSummary
          onSave={this.onSave}
          onClose={this.onClose}
          onClickEditDatetime={() => this.setState({ view: 'EditDatetime' })}
          onClickEditOnlineMeetingUrl={() => this.setState({ view: 'EditOnlineMeetingUrl' })}
          onClickDestroyAction={this.onClickDestroyAction}
          onClickConfirmAction={this.onClickConfirmAction}
          currentView={this.state.view}
          parentProps={this.props}
          state={this.state}
          changeState={this.changeState}
        />

      </div>
    </section>
  }
}

ShowModal.propTypes = {
  userCompany: PropTypes.object,
  targetCompany: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  timeSliceId: PropTypes.number,
  hidden: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  hostId: PropTypes.number,
  userCompanyId: PropTypes.number,
  userCompanyName: PropTypes.string,
  hostCompanyName: PropTypes.string,
  targetCompanyId: PropTypes.number,
  targetCompanyName: PropTypes.string,
}

export default ShowModal
